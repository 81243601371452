

const CardItem = ({ img }) => {
  return (
    <>
      <img src={img} alt="avatar" className='carditem' />
    </>
  )
}

export default CardItem
