import '../style/header.css'
// import { IoLogoChrome } from "react-icons/io";
import { FaReact } from "react-icons/fa";

const Header = () => {
  return (
    <header className='header'>
      <FaReact size={30} className='App_logo' />
      {/* <IoLogoChrome size={30} className='App_logo' /> */}
      <div className='header_link'>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#contact">Contact</a>
        <a href="#profile">Profile</a>
        <a href="#scills">Skills</a>
      </div>
    </header>
  )
}

export default Header
