
import './App.css';

import Header from './components/Header';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Scills from './pages/Scills';

function App() {
  return (
    <div className="app">
      <Header />

      <Home />
      <hr style={{ margin: '10px' }} />
      <About />
      <hr style={{ margin: '10px' }} />
      <Scills />
      <hr style={{ margin: '10px' }} />
      <Contact />
      <hr style={{ margin: '10px' }} />
      <Profile />
    </div>
  );
}

export default App;
