import '../style/about.css'
import Card from '../components/Card'

import one from '../images/1.jpeg'
import two from '../images/2.jpg'
import three from '../images/3.jpg'
import four from '../images/6.webp'

import { SiCreatereactapp, SiNodedotjs, SiHtml5, SiCss3, SiNextdotjs, SiMongodb, SiJavascript } from "react-icons/si";

const About = () => {
  return (
    <div className='card_about_container' id='about'>
      <div className='about'>
        <h1 style={{ color: 'orange' }}>About me</h1>
        <p style={{ fontStyle: 'italic', padding: '0 10px' }}>Привет! Меня зовут Александр и я опытный фронтенд-разработчик с более чем пятилетним опытом работы
          в создании пользовательских интерфейсов и веб-страниц.
          Мои навыки включают владение языками HTML, CSS и JavaScript,
          а также опыт работы с популярными фреймворками, такими как React и Next.js.</p>
        <div className='about_icons'>
          <SiCreatereactapp style={{ color: 'lightblue' }} />
          <SiCss3 style={{ color: 'blue' }} />
          <SiHtml5 style={{ color: 'blueviolet' }} />
          <SiMongodb style={{ color: 'green' }} />
          <SiNodedotjs style={{ color: 'orange' }} />
          <SiNextdotjs style={{ color: 'red' }} />
          <SiJavascript style={{ color: 'yellow' }} />
        </div>
        {/* <p style={{ padding: '10px' }}>Я всегда стараюсь обеспечить качественное тестирование своих работ. Я проверяю их совместимость с различными браузерами и устройствами, а также отлаживаю код и устраняю возникшие ошибки. Моя цель - создать продукт, который будет работать безупречно и на всех платформах.</p> */}
      </div>
      <div className='card_about'>
        <Card icon={one} text={'Expirence'} num={'3+'} />
        <Card icon={two} text={'Happy people'} num={'100+'} />
        <Card icon={three} text={'Award win'} num={'1'} />
        <Card icon={four} text={'Project done'} num={'200+'} />
      </div>

    </div>
  )
}

export default About
