import React from 'react'
import CaruselItem from '../components/Carusel'

const Scills = () => {
  return (
    <div className='scills' id='scills'>
      <h1 style={{ color: 'orange' }}>Skills</h1>
      <div>
        <p style={{ color: 'white', textAlign: 'center', padding: '0 20px' }}>В моей работе я сотрудничал с дизайнерами для реализации визуального облика проектов, основываясь на предоставленных макетах. Я умею воплощать дизайнерские идеи в интерактивные веб-страницы, сохраняя при этом внешний вид и создавая приятный пользовательский опыт. Я также активно использовал анимации и эффекты для придания интерактивности и привлекательности веб-страницам.</p>
      </div>
      <CaruselItem />
      <div>
        <p style={{ color: 'white', textAlign: 'center', padding: ' 20px' }}>Я всегда стараюсь обеспечить качественное тестирование своих работ. Я проверяю их совместимость с различными браузерами и устройствами, а также отлаживаю код и устраняю возникшие ошибки. Моя цель - создать продукт, который будет работать безупречно и на всех платформах.</p>
      </div>
    </div>
  )
}

export default Scills
