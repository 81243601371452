import React from 'react'
import { FaGithub, FaWhatsapp, FaTwitter } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import '../style/contact.css'

const Contact = () => {
  return (
    <div className='contact' id='contact'>
      <h1 style={{ color: 'orange' }}>Contact</h1>
      <div className='contact_container'>
        <div className='contact_icon'>
          <FaGithub size={30} />
          <a href="https://github.com/">https://github.com/</a>
        </div>
        <div className='contact_icon'>
          <FaWhatsapp size={30} style={{ color: 'green' }} />
          <p>+7 921 309 54 37</p>
        </div>
        <div className='contact_icon'>
          <IoIosMail size={30} style={{ color: 'red' }} />
          <p>plutalov70@mail.ru</p>
        </div>
        <div className='contact_icon'>
          <FaTwitter size={30} style={{ color: 'blue' }} />
          <p>twitter@example...</p>
        </div>
      </div>

    </div>
  )
}

export default Contact
