import '../style/card.css'

const Card = ({ icon, num, text }) => {
  return (
    <div className='card_container'>
      <img src={icon} alt="icon" className='card_icon' />
      <h1 className='card_h1'>{num}</h1>
      {/* <span>{icon}</span> */}
      <p className='card_h3'>{text}</p>
    </div>
  )
}

export default Card
