import React from 'react'
import profile from '../images/d.png'
import '../style/profile.css'

const Profile = () => {
  return (
    <div className='profile_container' id='profile'>
      <h1 style={{ color: 'orange' }}>Profile</h1>
      <div className='profile'>
        <img src={profile} alt="profile" className='dev' />
        <p>общение с клиентами и членами команды на понятном языке: русском или английском;
          тайм-менеджмент, чтобы не срывать дедлайны и организовать собственную работу;
          умение анализировать и внимание к деталям;
          критическое мышление, чтобы оценить задачу с разных сторон;
          желание развиваться как профессионал (IT-индустрия не стоит на месте);
          мультизадачность: иногда приходится одновременно созваниваться с командой, фиксить баги и дописывать новый код.
          оригинальный подход к решению задач.</p>
      </div>

    </div>
  )
}

export default Profile
