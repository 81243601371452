import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardItem from "./CardItem";
import css from '../images/css.jpg'
import html from '../images/html.jpg'
import mongo from '../images/mongodb.jpg'
import node from '../images/node.png'
import next from '../images/next.png'
import react from '../images/react.webp'

const responsive = {
  // superLargeDesktop: {
  //   // the naming can be any, depends on you.
  //   breakpoint: { max: 4000, min: 3000 },
  //   items: 5
  // },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const CaruselItem = () => {
  return (
    <Carousel responsive={responsive} additionalTransfrom={0} autoPlay={true}
      arrows infinite centerMode={false}
      autoPlaySpeed={3000}>
      <CardItem img={html} />
      <CardItem img={css} />
      <CardItem img={mongo} />
      <CardItem img={node} />
      <CardItem img={next} />
      <CardItem img={react} />
      {/* <CardItem icon={html} /> */}
    </Carousel>
  )
}

export default CaruselItem
