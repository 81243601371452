import BlogAnimation from "../components/BlogAnimation"
import dev from '../images/dev.avif'

const Home = () => {
  return (
    <div className='home' id="home">
      <div className='dev_container'>
        <img src={dev} alt="dev" className='dev' />
        <div className="dev_blog">
          <h1>Hi , my name <span style={{ color: 'yellow' }}>Alexandr</span></h1>
          <BlogAnimation />
        </div>

      </div>
    </div>
  )
}

export default Home
