import { TypeAnimation } from 'react-type-animation';

import React from 'react'

const BlogAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        'I am a frontend developer', // Types 'One'
        2000, // Waits 1s
        'Working on bekend and frontend', // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        'You can view my resume', // Types 'Three' without deleting 'Two'
        2000,
      ]}
      wrapper="span"
      cursor={true}
      repeat={Infinity}
      className='anime'
    // style={{ fontSize: '2em', display: 'inline-block', color: 'blue', fontFamily: 'Oswald' }}
    />
  )
}

export default BlogAnimation
